export const ORDER_STATUSES = {
	ORDER_PLACED: 'Order Placed',
	IN_PROGRESS: 'In Progress',
	DELIVERED: 'Delivered',
	NOT_DELIVERED: 'Not Delivered',
	CANCELED: 'Canceled',
	PAYMENT_INITIATED: 'Payment Initiated',
}

export default undefined
