/* eslint-disable implicit-arrow-linebreak */
import { Api } from '@/services/api'

export const initiateOrder = async (token, { formData }) =>
	Api(token).post('/V1/Order/InitiateOrder', formData)

export const getOrderStatusList = async (token) => Api(token).get('/V1/Order/GetOrderStatus')

export const getOrdersList = async (token, { params }) => {
	const urlQueryParams = new URLSearchParams(params).toString()
	const encodedParams = encodeURI(urlQueryParams) // encode the url to prevent XSS in search filter
	return Api(token).get(`/V1/Order/GetOrderList?${encodedParams}`)
}

export const getOrderDetails = async (token, { orderId }) =>
	Api(token).get(`/V1/Order/GetOrderDetails?orderId=${orderId}`)

export const confirmOrderPayment = async (token, { paymentIntentId }) =>
	Api(token).get(`/V1/Order/ConfirmOrderPayment?paymentIntentId=${paymentIntentId}`)

// Calculate delivery charge based on discount code and order items
// If discount code is present then will send prices w/ discount applied.
export const getCalculatedDeliveryCharges = async (token, { payload }) =>
	Api(token).post('/V1/Order/CalculateDeliveryCharges', payload)

// validate dicount code
export const checkIsValidVoucherCode = async (token, { code }) =>
	Api(token).get(`/V1/Order/ValidateDiscountCode?discountCode=${code}`)

export default undefined
