<template>
	<v-card elevation="0" class="h-full min-height-screen">
		<v-toolbar flat class="border-b-2 border--secondary mb-4 px-0 py-3 !h-85px">
			<v-toolbar-title>
				<a :href="`${baseUrl}?token=${authToken}`" class="text-decoration-none mr-2">
					<v-icon color="primary">mdi-arrow-left</v-icon>
				</a>
				<strong>My Orders</strong>
			</v-toolbar-title>

			<v-spacer></v-spacer>

			<!-- <v-btn icon>
				<v-icon>mdi-magnify</v-icon>
			</v-btn> -->
		</v-toolbar>

		<div class="px-3">
			<div
				v-if="isOrderStatusLoading"
				class="d-flex align-center justify-center w-full h-full"
				style="height: 400px"
			>
				<loader />
			</div>
			<div v-else-if="!hasError">
				<v-row class="mb-4" align="center">
					<v-col cols="5">
						<span class="text-base font-weight-bold">Order Status:</span>
					</v-col>

					<v-col cols="7">
						<v-select
							dense
							rounded
							outlined
							hide-details
							return-object
							class="rounded-small px-2 py-2"
							item-text="name"
							item-value="id"
							:items="orderStatusOptions"
							v-model="selectedOrderStatus"
						/>
					</v-col>
				</v-row>

				<div class="pb-5">
					<!-- orders loading spinner -->
					<div
						v-if="isOrdersLoading"
						class="d-flex align-center justify-center w-full h-full"
						style="height: 400px"
					>
						<loader />
					</div>
					<div v-else>
						<div v-if="orders && orders.length > 0">
							<a
								v-for="order in orders"
								:key="order.id"
								:href="`${baseUrl}/me/order/${order.id}?token=${authToken}`"
								class="text-decoration-none"
								ref="ordersListRef"
							>
								<v-card
									flat
									elevation="0"
									class="px-3 py-3 rounded border-2 border--secondary mt-4"
								>
									<div class="d-flex justify-space-between items-center flex-wrap">
										<div class="d-flex flex-column my-1 mr-2">
											<h3 class="mb-2 font-weight-bold text-base">
												Order ID: {{ order.orderNumber }}
											</h3>
											<span class="text-sm">
												<span>Total Amount: </span>
												<strong>{{ currencyFormatter.format(order.totalOrderAmount) }}</strong>
											</span>
										</div>
										<div class="d-flex flex-column justify-space-between my-1">
											<span class="text--secondary mb-2 font-weight-regular text-sm">
												{{ order.orderDate }}
											</span>
											<span class="text-sm" :style="orderStatusStyles">
												{{ order.status }}
											</span>
										</div>
									</div>
								</v-card>
							</a>

							<!-- show loading more spinner -->
							<transition name="fade" mode="out-in">
								<div
									v-if="isMoreOrdersLoading"
									class="d-flex align-center justify-center w-full h-full mt-6"
								>
									<loader />
								</div>
							</transition>
						</div>
						<div
							v-else-if="hasNoOrders"
							class="d-flex align-center justify-center w-full h-full mt-6"
						>
							<h3>No Orders found</h3>
						</div>
					</div>
				</div>
			</div>
			<div v-else class="w-full h-full d-flex align-center justify-center" style="height: 200px">
				<span class="text-base text-secondary">Oops!! something went wrong</span>
			</div>
		</div>
	</v-card>
</template>

<script>
import AuthMixin from '@/mixins/auth'
import Loader from '@/components/Loader.vue'
import currencyFormatter from '@/utils/currency-formatter'
import { getOrderStatusList, getOrdersList } from '@/services/order'
import { ORDER_STATUSES } from '@/constants/order-status'
import { SUCCESS, NOT_FOUND } from '@/constants/status-code'

const PAGE_SIZE = 10

export default {
	name: 'MyOrders',
	mixins: [AuthMixin],
	components: {
		Loader,
	},
	data() {
		return {
			currencyFormatter,
			isOrderStatusLoading: false,
			isOrdersLoading: false,
			isMoreOrdersLoading: false,
			hasError: false,
			selectedOrderStatus: null,
			orderStatusOptions: [],
			orders: [],
			pageNo: 1,
			observer: null,
			currentOrdersListLastElementRef: null,
		}
	},
	created() {
		this.fetchOrderStatus()
	},
	watch: {
		selectedOrderStatus() {
			this.pageNo = 1
			this.fetchOrdersList()
		},
	},
	computed: {
		hasNoOrders() {
			return !this.isOrdersLoading && Array.isArray(this.orders) && this.orders.length <= 0
		},
		orderStatusStyles() {
			if (this.selectedOrderStatus.name === ORDER_STATUSES.ORDER_PLACED) return { color: '#1E91CF' }
			if (this.selectedOrderStatus.name === ORDER_STATUSES.IN_PROGRESS) return { color: '#54B7D3' }
			if (this.selectedOrderStatus.name === ORDER_STATUSES.DELIVERED) return { color: '#2AA952' }
			if (this.selectedOrderStatus.name === ORDER_STATUSES.CANCELED) return { color: '#FF2525' }
			if (this.selectedOrderStatus.name === ORDER_STATUSES.NOT_DELIVERED) {
				return { color: '#FFA902' }
			}
			if (this.selectedOrderStatus.name === ORDER_STATUSES.PAYMENT_INITIATED) {
				return { color: '#94a3b8' }
			}
			return {}
		},
	},
	methods: {
		isIntersecting(entries) {
			entries.forEach(({ isIntersecting }) => {
				if (isIntersecting) {
					this.pageNo += this.pageNo
					this.fetchOrdersList({ fetchNext: true })
				}
			})
		},
		addOrRemoveObeserver(onlyRemoveObserver = false) {
			if (
				// eslint-disable-next-line operator-linebreak
				this.observer instanceof IntersectionObserver &&
				this.currentOrdersListLastElementRef
			) {
				this.observer.unobserve(this.currentOrdersListLastElementRef)
				this.observer = null
			}
			if (onlyRemoveObserver) return
			if (!this.$refs.ordersListRef) return

			// eslint-disable-next-line operator-linebreak
			this.currentOrdersListLastElementRef =
				this.$refs.ordersListRef[this.$refs.ordersListRef.length - 1]

			this.observer = new IntersectionObserver(this.isIntersecting)
			this.observer.observe(this.currentOrdersListLastElementRef)
		},
		async fetchOrderStatus() {
			this.isOrderStatusLoading = true
			try {
				const res = await getOrderStatusList(this.authToken)
				const { data, statusCode } = res.data

				if (statusCode !== SUCCESS) throw new Error('something went wrong')

				this.orderStatusOptions = data
				// select first order status by default
				// eslint-disable-next-line prefer-destructuring
				this.selectedOrderStatus = data[0]
			} catch (e) {
				this.hasError = true
				console.log(e, 'error')
			}
			this.isOrderStatusLoading = false
		},
		async fetchOrdersList(args = {}) {
			const { fetchNext } = args

			const params = {
				statusId: this.selectedOrderStatus.id,
				pageNo: this.pageNo,
				pageSize: PAGE_SIZE,
			}

			if (fetchNext) {
				this.isMoreOrdersLoading = true
			} else {
				this.isOrdersLoading = true
			}

			try {
				const res = await getOrdersList(this.authToken, { params })
				const { data, statusCode } = res.data

				if (statusCode === NOT_FOUND) {
					this.orders = []
				}

				if (statusCode === SUCCESS) {
					const { totalPage, result: orders } = data
					const hasMoreOrders = Array.isArray(orders) && orders.length > 0
					const removeObserver = !hasMoreOrders

					this.orders = fetchNext ? [...this.orders, ...orders] : orders
					// dont add observer if orders has only 1 or less page
					if (totalPage > 1) {
						this.$nextTick().then(() => {
							this.addOrRemoveObeserver(removeObserver)
						})
					}
				}
			} catch (e) {
				console.log(e, 'error')
			}

			if (fetchNext) {
				this.isMoreOrdersLoading = false
			} else {
				this.isOrdersLoading = false
			}
		},
	},
}
</script>

<style lang="scss" scoped>
:deep(.v-slide-group__content.v-tabs-bar__content) {
	justify-content: space-evenly;
}
:deep(.v-tabs:not(.v-tabs--vertical) .v-tab) {
	flex-grow: 1;
}
</style>
